import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_navigation_bar_e_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.primary.main,
      },
      {
        name: 'theme_component_navigation_bar_e_background_position',
        label: 'Background position',
        type: 'select',
        default: 'center',
        options: ['top', 'right', 'bottom', 'left', 'center'],
      },
      {
        name: 'theme_component_navigation_bar_e_background_size',
        label: 'Navigation bar Background Size',
        type: 'text',
        startAdornment: 'px',
        endAdornment: '',
      },
      {
        name: 'theme_component_navigation_bar_e_background_repeat',
        label: 'Background repeat',
        type: 'select',
        default: 'no-repeat',
        options: ['no-repeat', 'repeat-y', 'repeat-x', 'repeat'],
      },
      {
        name: 'theme_component_navigation_bar_e_border_bottom_width',
        label: 'Navigation Border Bottom Width',
        type: 'text',
        default: '1px',
      },
      {
        name: 'theme_component_navigation_bar_e_border_bottom_color',
        label: 'Navigation Border Bottom Width',
        type: 'color',
        default: 'black',
      },
      {
        name: 'theme_component_navigation_bar_logo_width',
        label: 'Header Logo Width',
        type: 'text',
        startAdornment: 'px',
        endAdornment: '',
      },
      {
        name: 'theme_component_navigation_bar_e_color',
        label: 'Text color',
        type: 'color',
        default: theme.palette.primary.contrastText,
      },
      {
        name: 'theme_component_navigation_bar_e_border_bottom_width',
        label: 'Border bottom width',
        type: 'number',
        default: 0,
        endAdornment: 'px',
      },
      {
        name: 'theme_component_navigation_bar_e_border_bottom_color',
        label: 'Border bottom color',
        type: 'color',
        default: theme.palette.divider,
      },
    ],
    configuration: [
      {
        name: 'component_navigation_bar_e_extra_logo_link',
        label: 'Extra Logo Image',
        type: 'text',
        default: '',
        helperText: 'Extra Image on right',
      },
      {
        name: 'component_navigation_bar_e_extra_image',
        label: 'Extra Logo Image link redirect',
        type: 'text',
        default: '',
        helperText: 'Extra image link to open a new tab',
      },
    ],
  });
}