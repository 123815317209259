import * as React from 'react';
import styled from '@emotion/styled';
import { useAppContext } from '../../contexts/AppContext';
import { useEditorHandlers } from './@editor';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from '../Link';
import Image, { ImageLoaderProps } from 'next/image';
import { Property } from 'csstype';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.theme_component_navigation_bar_e_background_color || theme.palette.primary.main,
  backgroundPosition: theme.theme_component_navigation_bar_e_background_position || 'center',
  backgroundSize: theme.theme_component_navigation_bar_e_background_size || 'cover',
  backgroundRepeat: theme.theme_component_navigation_bar_e_background_repeat || 'no-repeat',
  borderBottomWidth: theme.theme_component_navigation_bar_e_border_bottom_width
    ? `${theme.theme_component_navigation_bar_e_border_bottom_width}px`
    : 0,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.theme_component_navigation_bar_e_border_bottom_color || theme.palette.divider,
  color: theme.theme_component_navigation_bar_e_color || theme.palette.primary.contrastText,
  outlineOffset: '-2px', // Help out cursor context editor.
  position: theme.theme_component_navigation_position as Property.Position || 'sticky'
})) as typeof AppBar;

const ButtonLogo = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down(456)]: {
    height: '32px',
    minWidth: '40%',
  },
  [theme.breakpoints.down(767)]: {
    height: '48px',
    minWidth: '40%',
  },
  [theme.breakpoints.up(767)]: {
    width: theme.theme_component_navigation_bar_logo_width || '15%',
    height: '60px',
    minWidth: '200px',
  },
})) as typeof Button;

const ToolbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down(456)]: {
    height: '40px',
  },
  [theme.breakpoints.down(767)]: {
    height: '80px',
  },
  [theme.breakpoints.up(767)]: {
    height: '100px',
  },
}));

export const NavigationBarE = () => {
  const appContext = useAppContext();
  const { url, componentOptions, assetUrl, headerLogoId } = appContext.useConfig();

  const extraHeaderImageRedirect = componentOptions?.component_navigation_bar_e_extra_logo_link;
  const extraHeaderImage = componentOptions?.component_navigation_bar_e_extra_image;

  return (
    <AppBarStyled {...useEditorHandlers('Navigation Bar A')}>
      <Container maxWidth={false}>
        <ToolbarContainer>
          <ButtonLogo component={Link} href="/" title={url} aria-label="Home">
            <Image
              loader={({ src }: ImageLoaderProps) => src}
              src={`${assetUrl}${headerLogoId}/`}
              unoptimized
              alt={url}
              layout="fill"
              objectFit="contain"
            />
          </ButtonLogo>
          {extraHeaderImage && (
            <ButtonLogo component={Link} href={extraHeaderImageRedirect} title={extraHeaderImage} aria-label="Home">
              <Image
                loader={({ src }: ImageLoaderProps) => src}
                src={extraHeaderImage}
                alt={url}
                layout="fill"
                objectFit="contain"
              />
            </ButtonLogo>
          )}
        </ToolbarContainer>
      </Container>
    </AppBarStyled>
  );
};
